<!--门禁模块--访客黑名单信息管理-->
<template>
  <div id="visitorBlacklist" v-loading="loading" :element-loading-text="`${$t('visitorBlacklist.a1')}....`">
    <!-- 头部 -->
    <div class="header">
      <!-- 页面标题 -->
      <my-headertitle>{{ $t("visitorBlacklist.a2") }}</my-headertitle>
      <!-- 查询栏 -->
      <div class="query">
        <a-row :gutter="16">
          <!-- 自定义条件 -->
          <a-col :span="8">
            <a-input-group compact>
              <!-- 条件选择 -->
              <a-select v-model="form.condition1" :placeholder="$t('visitorBlacklist.a3')" style="width: 45%">
                <a-select-option v-for="item in conditions1" :key="item.key"> {{ item.title }} </a-select-option>
              </a-select>
              <!-- 输入条件 -->
              <a-input v-model="form.value1" :placeholder="queryCriteria" style="width: 55%"/>
            </a-input-group>
          </a-col>
          <!-- 查询按钮 -->
          <a-col :span="2">
            <a-button type="primary" @click="inquire"> {{ $t("visitorBlacklist.a8") }} </a-button>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="main" v-table-size="tableSize">
      <!-- 表格 -->
      <a-table :columns="columns" :dataSource="list" :pagination="pagination" :scroll="size" :rowKey="(record) => record.key">
        <template slot="chaozuo" slot-scope="text, record">
          <div>
            <a-popover placement="left">
              <template slot="content">
                <!-- 删除按钮 -->
                <a-button class="table_button" type="primary" @click="() => onDeleteVisitor(record)" style="margin-right:15px"> {{ $t("visitorBlacklist.a6") }} </a-button>
                <!-- 修改按钮 -->
                <a-button class="table_button" type="primary" @click="() => onEditVisitor(record)"> {{ $t("visitorBlacklist.a5") }} </a-button>
              </template>
              <a>{{ $t("visitorBlacklist.a7") }}</a>
            </a-popover>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 底部功能栏 -->
    <div class="footer">
        <!-- <a-button type="primary" @click="onAddVisitor">{{$t('visitorBlacklist.a4')}}</a-button> -->
        <div>
            <!-- 下载模板文件 -->
            <a-button type="primary" @click="downloadTemplateFile">{{   $t("plateNumber.a89") }}</a-button>
            <!-- 上传黑名单信息 -->
            <a-button type="primary" @click="openUploadModel">{{$t('visitorBlacklist.a20')}}</a-button>
            <!-- 导出车牌信息 -->
            <!-- <a-button type="primary" @click="downloadCarPlateNumber">{{   $t("plateNumber.a91") }}</a-button> -->
        </div>
        <div style="text-align:right;">
            <a-button type="primary" @click="onAddVisitor">{{$t('visitorBlacklist.a4')}}</a-button>
        </div>
    </div>
 
    <!-- 添加/修改对话框 -->
    <a-modal :title="title" centered :width="600" :visible="visible" @ok="handleOk" @cancel="handleCancel" :destroyOnClose="true" v-drag>
      <my-visitorEditModal ref="modal" :editVisitor="editVisitor" :loading="visitorBlacklistEditModal_loading"></my-visitorEditModal>
    </a-modal>
    <uploadDialog :idInfo="idInfo"  :title="$t('visitorBlacklist.a20')"></uploadDialog>
  </div>                                                                                                                                                                  
</template>

<script>
import tabletitle from "../../components/Title/tabletitle"
import headerTitle from "../../components/Title/headerTitle"
import visitorEditModal from "./visitorBlacklistEditModal"
import { getVisitorBlacklistList,deleteVisitorBlacklist,updateVisitorBlacklist,insertVisitorBlacklist,downloadVisitorBlackList  } from "../../api/visitor"
import moment from "moment"
import uploadDialog from "./blacklistUploadDialog";
import { downloadUrl } from "../../utils/utils";

export default {
  name: "visitorBlacklist",
  data() {
    return {
      title:this.$t('visitorBlacklist.a4'),
      isNew: false,
      editVisitor:{},
      visible: false,
      form:{
        date1: moment().startOf("day"),
        date2: moment().endOf("day"),
        value1: "",
        condition1: 'visitorName'            
      },
      conditions1:[
        {key:"visitorName",title:this.$t('visitorBlacklist.a12')},
        {key:"visitorPhone",title:this.$t('visitorBlacklist.a13')}
      ],
      loading: false,
      visitorBlacklistEditModal_loading: false,
      size: {  x: 1500,y: 240 },
      list: [],
      pagination: {
        total: 0, //数据总数
        pageSize: 100, //每页中显示10条数据
        showTotal: (total) => `${total} ${this.$t("visitorBlacklist.a11")}`, //分页中显示总的数据
        showQuickJumper: true, //是否可以快速跳转至某页
        defaultCurrent: 1, //默认当前页面数
        hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
        onShowSizeChange: (current, pageSize) => {
          this.pagination.defaultCurrent = 1
          this.pagination.pageSize = pageSize
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.pagination.defaultCurrent = current
          this.pagination.pageSize = size
        }
      },
      columns: [
        {
          title: "NO",
          customRender: (text, record, index) =>
            `${
              (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
              index +
              1
            }`,
          fixed: 'left' ,
          align: 'center',
          width: 60
        },        
        {
          title: this.$t("visitorBlacklist.a12"),
          dataIndex: "visitorName",
          fixed: 'left' ,
          align: 'center',
          width: 150
        },
        {
          title: this.$t("visitorBlacklist.a13"),
          dataIndex: "visitorPhone",
          align: 'center',
          width: 200
        },
        {
          title: this.$t("visitorBlacklist.a14"),
          dataIndex: "certificateTypeName",
          align: 'center',
          width: 200
        },
        {
          title: this.$t("visitorBlacklist.a15"),
          dataIndex: "certificateNo",
          align: 'center',
          width: 200
        },
        {
          title: this.$t("visitorBlacklist.a16"),
          dataIndex: "dateTime",
          align: 'center',
          width: 200
        },
        {
          title: this.$t("visitorBlacklist.a17"),
          dataIndex: "memo"
        },           
        {
          title: this.$t("visitorBlacklist.a7"),
          dataIndex: "chaozuo",
          fixed: 'right',
          scopedSlots: { customRender: "chaozuo" },
          width: 100
        }
      ],
      blacklistUploadDialogVisible:false,
      idInfo:{
          type:0,
          value:0, 
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: this.$route.query.id,
      },
    }
  },
  computed:{
    // 查询条件输入框占位符
    queryCriteria: function (){
      let conditions1 = this.form.condition1
      if(conditions1 === 'visitorName'){
        return this.$t('visitorBlacklist.a12')
      } else if(conditions1 === 'visitorPhone'){
        return this.$t('visitorBlacklist.a13')
      } else {
        return ''
      }
    },
  },
  methods: {
    openUploadModel(){
      this.blacklistUploadDialogVisible = true;
    },
    downloadTemplateFile(){
        this.loading = true;
        let param = {
            action: 9,
            clientId: this.$store.getters.clientId,
            sitegrId: this.$store.getters.sitegrId,
            siteId: this.$route.query.id,
        };
        downloadVisitorBlackList(param)
        .then((res) => {
            console.log('downloadTemplateFile RESULT',res);
            this.loading = false;
            let name  = (this.$store.getters.siteName?this.$store.getters.siteName+'-':'');
            name+=this.$t('visitorBlacklist.a4');
            name+="-";
            name+=this.$t('plateNumber.a101');
            name+="-";
            name+=moment().format('YYYYMMDDHHmmssSSS')+'.zip';
            console.log("downloadTemplateFile Result>>",name);
            downloadUrl(res,name);
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
      
    },
    moment,
    // 表格高度计算
    tableSize() {
      const indexDom = document.getElementById('visitorBlacklist')
      if (indexDom !== null) {
        const height = indexDom.getElementsByClassName('main')[0].clientHeight
        const title = indexDom.getElementsByClassName('ant-table-thead')[0].clientHeight
        const tableHeight = height - 64 - title
        this.size.y = tableHeight
      }
    },
    // 添加访客记录
    onAddVisitor() {   
      this.title = this.$t('visitorBlacklist.a4')
      this.editVisitor = {
        certificateNo: "",
        certificateType: 0,
        id: 0,
        memo: "",
        tenantId: 0,
        visitorAddress: "",
        visitorCompany: "",
        visitorDept: "",
        visitorName: "",
        visitorPhone: "",
        visitorPosition: "",
        visitorTelPhone: "",
        visitorType: 0,
        visitorWechat: ""
      }
      this.isNew = true
      this.visible = true
    },
    // 修改访客记录
    onEditVisitor(record) {
      this.title = this.$t('visitorBlacklist.a5')
      const _editVisitor = JSON.stringify(record)
      const editVisitor = JSON.parse(_editVisitor)
      this.editVisitor = editVisitor
      this.isNew = false
      this.visible = true
    },
    // 删除访客记录
    onDeleteVisitor(record) {
      console.log("deleteVisitorBlacklist siteId:",this.$route.query.id)
      console.log("deleteVisitorBlacklist personInfoId:",record.id)
      this.$confirm({
        title: this.$t('visitorBlacklist.a22'),
        content: record.visitorName,
        centered: true,
        onOk:()=> {
          this.loading = true
          deleteVisitorBlacklist(this.$route.query.id,record.id)
          .then((res) => {         
            let { data, errorCode, msg } = res
            if(errorCode === '00'){
              this.list = this.list.filter(item => { return item.id != record.id })
              this.$message.success(msg)
            }else{
              this.$message.error(msg) 
            }
            this.loading = false
            // console.log("deleteVisitorBlacklist res:",data)
          })
          .catch((err) => {
            this.loading = false;
            this.$message.error(msg)
            console.log(err)
          })
        }
      })
    },
    // 名单添加和修改
    handleOk(){  
      this.$refs.modal.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.visitorBlacklistEditModal_loading = true
          let par = {
                    certificateNo: this.editVisitor.certificateNo,
                    certificateType: this.editVisitor.certificateType,
                    id: this.editVisitor.id,
                    memo: this.editVisitor.memo,
                    tenantId: this.editVisitor.tenantId,
                    visitorAddress: this.editVisitor.visitorAddress,
                    visitorCompany: this.editVisitor.visitorCompany,
                    visitorDept: this.editVisitor.visitorDept,
                    visitorName: this.editVisitor.visitorName,
                    visitorPhone: this.editVisitor.visitorPhone,
                    visitorPosition: this.editVisitor.visitorPosition,
                    visitorTelPhone: this.editVisitor.visitorTelPhone,
                    visitorType: this.editVisitor.visitorType,
                    visitorWechat: this.editVisitor.visitorWechat
                  }
          console.log("insertVisitorBlacklist siteId:",this.$route.query.id)
          console.log("insertVisitorBlacklist par:",par)
          // isNew: true为添加名单，false为修改名单
          if(this.isNew){
            // 添加名单
            insertVisitorBlacklist(this.$route.query.id,par)
              .then((res) => {            
                let { data, errorCode, msg } = res
                if(errorCode === '00'){
                  this.$message.success(msg)
                  data.key = this.list.length
                  this.list.push(data)
                }else{
                  this.$message.error(msg)
                }
                this.visitorBlacklistEditModal_loading = false
                // console.log("insertVisitorBlacklist res:",data)
              })
              .catch((err) => {
                this.visitorBlacklistEditModal_loading = false
                this.$message.error(this.$t('visitorBlacklist.a19'))
                console.log(err)
              })
          }else{
            // 修改名单
            updateVisitorBlacklist(this.$route.query.id,par)
              .then((res) => {   
                let { data, errorCode, msg } = res
                if(errorCode === '00'){
                  const editVisitor = this.editVisitor
                  this.list = this.list.map(item => {
                    if(item.id === editVisitor.id){
                      return editVisitor
                    }else{
                      return item
                    }
                  })
                  this.$message.success(msg)
                }else{
                  this.$message.error(msg)
                }
                this.visitorBlacklistEditModal_loading = false
                // console.log("updateVisitorBlacklist res:",data)
              })
              .catch((err) => {
                this.visitorBlacklistEditModal_loading = false;
                this.$message.error(this.$t('visitorBlacklist.a19'))
                console.log(err)
              });
          }
          this.visible = false
        } else {
          console.log('error submit!!')
          return false
        }
      }) 
    },
    handleCancel(){
      this.visible = false;
    },
    // 条件查询方法
    inquire() {
      this.loading = true
      let par = {
        certificateNo: '',
        certificateType: 0,
        id: 0,
        memo: '',
        tenantId: 0,
        visitorAddress: '',
        visitorType: '',
        visitorWechat: '',
        visitorName: this.form.condition1 == "visitorName" ? this.form.value1 : "",
        visitorTelPhone: this.form.condition1 == "visitorTelPhone" ?this.form.value1 : "",
        visitorPhone: this.form.condition1 == "visitorPhone" ? this.form.value1 : "",
        visitorCompany: this.form.condition1 == "visitorCompany" ?this.form.value1 : "",
        visitorDept: this.form.condition1 == "visitorDept" ? this.form.value1 : "",
        visitorPosition: this.form.condition1 == "visitorPosition" ? this.form.value1 : ""
      }
      console.log("getVisitorBlacklistList siteId:",this.$route.query.id)
      console.log("getVisitorBlacklistList par:",par)
      getVisitorBlacklistList(this.$route.query.id,par)
        .then((res) => {           
          let { data, errorCode } = res
          if(errorCode === '00'){
            for (let i = 0; i < data.length; i++) { data[i].key = i }
            this.list = data
          }else{
            return false
          }
          this.loading = false
          // console.log("getVisitorBlacklistList res:",this.list);
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
        })
    }
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-headertitle": headerTitle,
    "my-visitorEditModal":visitorEditModal,
    uploadDialog,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#visitorBlacklist {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.header {
  min-width: 850px;
  height: 113px;
  padding: 0 20px;
  overflow: hidden;
}
.query{
  margin: 15px 0px;
}
.main {
  height: calc(100% - 168px);
  width: 100%;
  min-width: 670px;
  padding: 0px 20px;
  overflow: hidden;
}        

button {
  margin-right: 10px;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  justify-content:space-between;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;
}
</style>                                                                        