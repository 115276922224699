import { render, staticRenderFns } from "./blacklistUploadDialog.vue?vue&type=template&id=6d3f8c56&scoped=true"
import script from "./blacklistUploadDialog.vue?vue&type=script&lang=js"
export * from "./blacklistUploadDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d3f8c56",
  null
  
)

export default component.exports